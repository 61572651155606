
import { Component, Vue } from 'vue-property-decorator'
import { minuteFormat } from '@/utils/formatDate'
import { ElForm } from 'element-ui/types/form'

@Component({
  name: 'EventManage',
  filters: {
    statusFilters (value: number) {
      return value === 0 ? '未处理' : value === 1 ? '已处理' : ''
    }
  }
})
export default class extends Vue {
  private searchInfo = {
    projectId: '',
    alarmType: '',
    status: '',
    objectId: ''
  }

  private info = {
    suggestion: null
  }

  private rules = {
    suggestion: [{ required: true, message: '请输入处理意见', trigger: ['change'] }]
  }

  private detailData={

  }

  private date: string[] = []

  private typeList = []

  private dataList = []
  private loading = false
  private projectList = []

  page = 1
  size = 10
  total = 0

  created () {
    this.getRouteParam()
    this.getDate()
    this.getType()
    this.getProjectList()
  }

  // 获取参数
  getRouteParam () {
    this.searchInfo.objectId = this.$route.query.id as string
  }

  // 获取七天前时间
  getDate () {
    const date = new Date()
    const time = date.getTime()
    const beforeMs = time - 3600 * 1000 * 24 * 7
    const beforeDate = date.setTime(beforeMs)
    this.date = [minuteFormat(beforeDate), minuteFormat(date.setTime(time))]
    this.onSearch()
  }

  getData () {
    this.loading = true
    const info = this.date ? {
      ...this.searchInfo,
      startTime: this.date[0],
      endTime: this.date[1]
    } : this.searchInfo
    this.$axios.get(this.$apis.eventManage.selectCameraAlarmByPage, {
      ...info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.dataList = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  getType () {
    this.$axios.get(this.$apis.common.selectDicByList, {
      dicType: 'cameraConfig'
    }).then((res) => {
      this.typeList = res.cameraConfig || []
    })
  }

  getProjectList () {
    this.$axios.get(this.$apis.common.selectDeviceProjectList, {
      deviceTypeId: '1002'
    }).then((res) => {
      this.projectList = res.list || []
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  onDetail (id: string) {
    this.$axios.get(this.$apis.eventManage.selectCameraAlarmByAlarmId, {
      alarmId: id
    }).then((res) => {
      this.detailData = res || []
      this.$nextTick(() => {
        (this.$refs.info as ElForm).clearValidate()
        this.info.suggestion = null
      })
    })
  }

  onRules (id: string, value: number) {
    if (value === 1) {
      (this.$refs.info as ElForm).validate(valid => {
        if (valid) {
          this.onSubmit(id)
        }
      })
    } else {
      this.info.suggestion = null
      this.$nextTick(() => {
        (this.$refs.info as ElForm).clearValidate()
      })
      this.onSubmit(id)
    }
  }

  onSubmit (id: string) {
    this.$axios.post(this.$apis.eventManage.updateCameraAlarm, {
      ...this.info,
      status: 1,
      alarmId: id
    }).then(() => {
      this.$message.success('操作成功')
      this.onSearch()
      this.onDetail(id)
    })
  }

  onClose () {
    this.detailData = {}
  }
}
